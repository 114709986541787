<template lang="html">
  <v-container pa-0 fluid>
    <v-row dense>
      <v-col cols="12">
        <v-model-table model-name="Users" model-title="Users"
          :model-api="['models','User','Users']" :headers="headers" 
          :dialog-width="($vuetify.breakpoint.mdAndUp) ? '50vw' : '80vw'"
          searchable hideInsertButton>
          <template v-slot:modelForm="{data,rules,isCreating}">
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-text-field :rules="[rules.require()]" v-model="data.name" label="ชื่อผู้ใช้"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="data.email" label="Email"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-master-field :rules="[rules.require()]" v-model="data.position" groupKey="$UserPosition" label="ตำแหน่ง"></v-master-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="data.licenseNo" label="เลขใบประกอบวิชาชีพ"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-tabs-group>
                    <template v-slot:tabs>
                      <v-tab key="Roles">Roles</v-tab>
                      <v-tab key="Permissions">Permissions</v-tab>
                    </template>
                    <template v-slot:items>
                      <v-tab-item key="Roles">
                        <v-table-field v-model="data.roles" title="Roles" item-key="roleId" :headers="rolesHeaders" hide-toolbar show-create-pad auto-item-action>
                          <template v-slot:createPad="{data: tmplData,rules}">
                            <v-container fluid pa-0>
                              <v-row>
                                <v-col cols="12">
                                  <v-master-field :rules="[rules.require()]" v-model="tmplData.roleId"
                                    :custom-api="['models','User','Roles','query']" 
                                    custom-api-text="roleName" custom-api-value="roleId" 
                                    label="Roles" 
                                    customApiEagerLoad show-code>
                                  </v-master-field>
                                </v-col>
                              </v-row>
                            </v-container>
                          </template>
                          <template v-slot:item.roleName="props">
                            <v-label-api :api-url="['models','User','Roles','find']" :api-key="props.item.roleId" api-field="roleName" ></v-label-api>
                          </template>
                        </v-table-field>
                      </v-tab-item>
                      <v-tab-item key="Permissions">
                        <v-table-field v-model="data.permissions" title="Permissions" item-key="permissionId" :headers="permissionsHeaders" hide-toolbar show-create-pad auto-item-action>
                          <template v-slot:createPad="{data: tmplData,rules}">
                            <v-container fluid pa-0>
                              <v-row>
                                <v-col cols="12">
                                  <v-master-field :rules="[rules.require()]" v-model="tmplData.permissionId"
                                    :custom-api="['models','User','Permissions','query']" 
                                    custom-api-text="description" custom-api-value="permissionId" 
                                    label="Permissions" 
                                    customApiEagerLoad show-code>
                                  </v-master-field>
                                </v-col>
                              </v-row>
                            </v-container>
                          </template>
                          <template v-slot:item.description="props">
                            <v-label-api :api-url="['models','User','Permissions','find']" :api-key="props.item.permissionId" api-field="description" ></v-label-api>
                          </template>
                        </v-table-field>
                      </v-tab-item>
                    </template>
                  </v-tabs-group>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:item.position="props">
            <v-label-master group-key="$UserPosition" :item-code="props.item.position"></v-label-master>
          </template>
        </v-model-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: ()=>({
    headers: [
      {
        text: 'Username',
        value: 'username',
        class: 'body-2'
      },
      {
        text: 'ชื่อผู้ใช้',
        value: 'name',
        class: 'body-2'
      },
      {
        text: 'ตำแหน่ง',
        value: 'position',
        class: 'body-2'
      },
      {
        text: 'เลขที่ใบประกอบวิชาชีพ',
        value: 'licenseNo',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
    rolesHeaders: [
      {
        text: 'Role ID',
        value: 'roleId',
        class: 'body-2'
      },
      {
        text: 'Role Name',
        value: 'roleName',
        class: 'body-2'
      },
    ],
    permissionsHeaders: [
      {
        text: 'Permission ID',
        value: 'permissionId',
        class: 'body-2'
      },
      {
        text: 'Description',
        value: 'description',
        class: 'body-2'
      },
    ],
  }),
}
</script>

<style lang="css">
</style>
