var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"pa-0":"","fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-model-table',{attrs:{"model-name":"Users","model-title":"Users","model-api":['models','User','Users'],"headers":_vm.headers,"dialog-width":(_vm.$vuetify.breakpoint.mdAndUp) ? '50vw' : '80vw',"searchable":"","hideInsertButton":""},scopedSlots:_vm._u([{key:"modelForm",fn:function(ref){
var data = ref.data;
var rules = ref.rules;
var isCreating = ref.isCreating;
return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rules":[rules.require()],"label":"ชื่อผู้ใช้"},model:{value:(data.name),callback:function ($$v) {_vm.$set(data, "name", $$v)},expression:"data.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Email"},model:{value:(data.email),callback:function ($$v) {_vm.$set(data, "email", $$v)},expression:"data.email"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-master-field',{attrs:{"rules":[rules.require()],"groupKey":"$UserPosition","label":"ตำแหน่ง"},model:{value:(data.position),callback:function ($$v) {_vm.$set(data, "position", $$v)},expression:"data.position"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"เลขใบประกอบวิชาชีพ"},model:{value:(data.licenseNo),callback:function ($$v) {_vm.$set(data, "licenseNo", $$v)},expression:"data.licenseNo"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs-group',{scopedSlots:_vm._u([{key:"tabs",fn:function(){return [_c('v-tab',{key:"Roles"},[_vm._v("Roles")]),_c('v-tab',{key:"Permissions"},[_vm._v("Permissions")])]},proxy:true},{key:"items",fn:function(){return [_c('v-tab-item',{key:"Roles"},[_c('v-table-field',{attrs:{"title":"Roles","item-key":"roleId","headers":_vm.rolesHeaders,"hide-toolbar":"","show-create-pad":"","auto-item-action":""},scopedSlots:_vm._u([{key:"createPad",fn:function(ref){
var tmplData = ref.data;
var rules = ref.rules;
return [_c('v-container',{attrs:{"fluid":"","pa-0":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-master-field',{attrs:{"rules":[rules.require()],"custom-api":['models','User','Roles','query'],"custom-api-text":"roleName","custom-api-value":"roleId","label":"Roles","customApiEagerLoad":"","show-code":""},model:{value:(tmplData.roleId),callback:function ($$v) {_vm.$set(tmplData, "roleId", $$v)},expression:"tmplData.roleId"}})],1)],1)],1)]}},{key:"item.roleName",fn:function(props){return [_c('v-label-api',{attrs:{"api-url":['models','User','Roles','find'],"api-key":props.item.roleId,"api-field":"roleName"}})]}}],null,true),model:{value:(data.roles),callback:function ($$v) {_vm.$set(data, "roles", $$v)},expression:"data.roles"}})],1),_c('v-tab-item',{key:"Permissions"},[_c('v-table-field',{attrs:{"title":"Permissions","item-key":"permissionId","headers":_vm.permissionsHeaders,"hide-toolbar":"","show-create-pad":"","auto-item-action":""},scopedSlots:_vm._u([{key:"createPad",fn:function(ref){
var tmplData = ref.data;
var rules = ref.rules;
return [_c('v-container',{attrs:{"fluid":"","pa-0":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-master-field',{attrs:{"rules":[rules.require()],"custom-api":['models','User','Permissions','query'],"custom-api-text":"description","custom-api-value":"permissionId","label":"Permissions","customApiEagerLoad":"","show-code":""},model:{value:(tmplData.permissionId),callback:function ($$v) {_vm.$set(tmplData, "permissionId", $$v)},expression:"tmplData.permissionId"}})],1)],1)],1)]}},{key:"item.description",fn:function(props){return [_c('v-label-api',{attrs:{"api-url":['models','User','Permissions','find'],"api-key":props.item.permissionId,"api-field":"description"}})]}}],null,true),model:{value:(data.permissions),callback:function ($$v) {_vm.$set(data, "permissions", $$v)},expression:"data.permissions"}})],1)]},proxy:true}],null,true)})],1)],1)],1)]}},{key:"item.position",fn:function(props){return [_c('v-label-master',{attrs:{"group-key":"$UserPosition","item-code":props.item.position}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }